import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { throttle } from "throttle-debounce"
import styles from './SlideShow.module.scss'

const SlideShow = ({ children = [], animationTime = 300, classNameContainer, slideHeight, idRoot, paddingLeft = 0, paddingRight = 0, marginRight = 0, windowInnerWidth, isFullSlide, isClientSlider, customWrappStyle = {}, marginTopItem, disableSwipe, callBackChangeSlide, isCustomServerSlice, leftMenuIsOpen, showElements = windowInnerWidth <= 450 ? 1 : windowInnerWidth <= 750 ? 1 : windowInnerWidth <= 1100 ? 3 : 4, disableChangeSlide = windowInnerWidth <= 450 ? children.length <= 1 : windowInnerWidth <= 750 ? children.length <= 1 : windowInnerWidth <= 1100 ? children.length <= 3 : children.length <= 4, hideBottomLine }) => {
  console.log(windowInnerWidth)
  const firstSlideAnimationTime = 200
  const [ rootElement, setRootElement ] = useState({})
  const [ rootElementWidth, setRootElementWidth ] = useState(null)

  const touchOptionsModel = {
    startX: null,
    endTouchX: null,
    endTouchXNegative: null,
    currentStartedX: null
  }

  const [ touchOptions, setTouchOptions ] = useState(JSON.parse(JSON.stringify(touchOptionsModel)))
  const [ wrapElement, setWrapElement ] = useState({})
  const [ containerElement, setContainerElement ] = useState({})
  const [ copyChildren, setCopyChildren ] = useState([])
  const [ slideOption, setSlideOption ] = useState({
    totalSlide: children.length,
    currentIndex: 0,
    currentElementIndex: 0
  })
  const [ slideIsOver, setSlideIsOver ] = useState(false)

  const { currentStartedX } = touchOptions

  useEffect(() => {
    setContainerElement(document.querySelector('.' + styles.container + classNameContainer))
    setSlideOption({
      totalSlide: children.length,
      currentIndex: 0,
      currentElementIndex: 0
    })
    document.querySelector('.' + styles.container + classNameContainer).style.marginLeft = 0
  }, [ children ])

  useEffect(() => {
    const elem = document.getElementById(styles.root + idRoot)
    setRootElement(elem)
    // setRootElementWidth(elem.offsetWidth)
  }, [ rootElement ])

  useEffect(() => {
    setWrapElement(document.querySelector('.' + classNameContainer))
  }, [ wrapElement ])

  useEffect(() => {
    setTransition()
  }, [ containerElement, windowInnerWidth, copyChildren ])

  const setTransition = () => {
    let container = document.querySelector('.' + styles.container + classNameContainer)
    setContainerElement(container)
    container.style.transition = 'left ease ' + animationTime + 'ms'
    let slideItem = document.querySelectorAll('.' + styles.container + classNameContainer + '>div')
  }

  useEffect(() => {
    setMarginLeftContainer()
  }, [ windowInnerWidth ])

  useEffect(() => {
    setTimeout(() => {
      setRootElementWidth(rootElement.offsetWidth)
    }, 150)
  }, [ leftMenuIsOpen ])

  const setMarginLeftContainer = () => {
    document.querySelector('.' + styles.container + classNameContainer).style.marginLeft = (slideOption.currentIndex * (wrapElement.offsetWidth / (isFullSlide ? 1 : showElements)) + 'px')
  }

  useEffect(() => {
    console.log(children)
    setCopyChildren(children.slice())
  }, [ children ])

  useEffect(() => {
    if (!!callBackChangeSlide) callBackChangeSlide(copyChildren[ 0 ])
  }, [ copyChildren ])

  const changeSlide = slide => {
    if (slideIsOver || disableChangeSlide) return false
    else {
      setSlideIsOver(true)
      let newChildrenArr = []
      let currentIndex = slideOption.currentIndex + (isFullSlide ? slide * showElements : slide)
      console.log(currentIndex, (isFullSlide ? slide * showElements : slide))
      let currentElementIndex = slideOption.currentElementIndex + (isFullSlide ? slide * showElements : slide)
      console.log(currentElementIndex, copyChildren.length)

      const slideElement = isFullSlide ? showElements : 1

      const elements = isFullSlide ? (Math.ceil(copyChildren.length / showElements)) * showElements - showElements : copyChildren.length - 1

      currentElementIndex = currentElementIndex < 0 ? elements : currentElementIndex > elements ? 0 : currentElementIndex
      console.log(currentElementIndex)

      if (currentElementIndex === 0) newChildrenArr = children.slice()
      else if (slide === 1) {
        let firstChild = isFullSlide ? copyChildren.slice(0, showElements) : [ copyChildren[ 0 ] ]
        newChildrenArr = [ ...copyChildren.slice(isFullSlide ? showElements : 1, copyChildren.length), ...firstChild ]
      }
      else {
        let lastChild = isFullSlide ? copyChildren.slice(copyChildren.length - showElements, copyChildren.length) : [ copyChildren[ copyChildren.length - 1 ] ]
        newChildrenArr = [ ...lastChild, ...copyChildren.slice(0, isFullSlide ? copyChildren.length - showElements : copyChildren.length - 1) ]
      }

      setSlideOption({ ...slideOption, currentIndex, currentElementIndex })

      setTimeout(() => {
        setCopyChildren(newChildrenArr)
        document.querySelector('.' + styles.container + classNameContainer).style.marginLeft = (currentIndex * (wrapElement.offsetWidth / (showElements)) + 'px')
      }, currentElementIndex === 0 && String(children.length / showElements).indexOf('.') > 0 && slide === 1 ? firstSlideAnimationTime : slide === 1 ? animationTime : 0)

      setTimeout(() => {
        setSlideIsOver(false)
      }, animationTime)
    }
  }

  const setCurrentSlide = index => {
    if (slideOption.currentElementIndex === index) return
    else {
      let container = document.querySelector('.' + styles.container + classNameContainer)
      container.style.transition = 'none'
      index = isFullSlide ? index * showElements : index
      let currentIndex = index - slideOption.currentElementIndex
      console.log(index, slideOption.currentElementIndex, currentIndex)

      let newChildrenArr = []
      if (index === 0) newChildrenArr = children.slice()
      else if (currentIndex >= 1) {
        let firstChild = copyChildren.slice(0, Math.abs(currentIndex))
        console.log(firstChild)
        newChildrenArr = [ ...copyChildren.slice(Math.abs(currentIndex), copyChildren.length), ...firstChild ]
      }
      else {
        let lastChild = copyChildren.slice(copyChildren.length - Math.abs(currentIndex), copyChildren.length)
        copyChildren.splice(copyChildren.length - Math.abs(currentIndex), lastChild.length)
        newChildrenArr = [ ...lastChild, ...copyChildren ]
      }


      setSlideOption({
        ...slideOption,
        currentIndex: slideOption.currentIndex + (currentIndex),
        currentElementIndex: index
      })

      setCopyChildren(newChildrenArr)
      container.style.marginLeft = ((slideOption.currentIndex + (currentIndex)) * (wrapElement.offsetWidth / (showElements)) + 'px')

      setTimeout(() => container.style.transition = 'left ease ' + animationTime + 'ms', animationTime)
    }
  }

  const touchStartHandler = (event) => {
    const touch = event.touches[ 0 ]
    const startX = touch.pageX
    const elementWidth = ((wrapElement.offsetWidth - ((showElements - 1) * (marginRight || 0))) / showElements)
    const endTouchX = (elementWidth * 30 / 100) + startX
    const endTouchXNegative = startX - (elementWidth * 30 / 100)

    setTouchOptions({
      startX,
      endTouchX,
      endTouchXNegative
    })
  }

  const touchMoveHandler = (event) => {
    const { startX, endTouchX, endTouchXNegative } = touchOptions
    const touch = event.touches[ 0 ].pageX

    if (startX === null || touch === startX) return
    else {
      if (touch <= startX) {
        if (touch <= endTouchXNegative) {
          changeSlide(1)
          touchEndHandler()
        }
        else {
          setTouchOptions({
            ...touchOptions,
            currentStartedX: (startX - touch) * -1
          })
        }
      }
      else {
        if (touch >= endTouchX) {
          changeSlide(-1)
          touchEndHandler()
        }
        else {
          console.log(touch - startX, endTouchX, startX, touch)

          setTouchOptions({
            ...touchOptions,
            currentStartedX: touch - startX
          })
        }
      }
    }
    //console.log(event.touches, touchOptions)
  }

  const touchEndHandler = () => {
    setTouchOptions(JSON.parse(JSON.stringify(touchOptionsModel)))
  }

  let wrappStyle = {}

  if ([ 4, 3, 2 ].some(item => showElements === item && children.length < item)) {
    wrappStyle = {
      display: 'flex',
      justifyContent: 'center'
    }
  }

  const setSlide = e => {
    const rect = e.currentTarget.getBoundingClientRect()
    const { width, left } = rect
    const x = e.clientX - left
    let currentPositionToPercent = Math.floor(x * 100 / width)
    console.log(x, currentPositionToPercent)
    const currentIndex = Math.floor((isFullSlide ? Math.ceil(copyChildren.length / showElements) : copyChildren.length) * currentPositionToPercent / 100)
    setCurrentSlide(currentIndex)
  }

  const calcProgressIndex = () => {
    let index = (slideOption.currentElementIndex + (isFullSlide ? showElements : 1))
    return index > copyChildren.length ? copyChildren.length : index
  }

  console.log(rootElement.offsetWidth, rootElementWidth)
  return (
    <>
      <style jsx global>{
        `
        .${ styles.container + classNameContainer } > div {
          width: calc(100% / ${ children.length } - ${ showElements === 1 ? 0 : marginRight }px);
          margin-right: ${ (marginRight || 0) + 'px' };
          height: ${ (slideHeight - (!!marginTopItem ? marginTopItem : 0)) + 'px' }
        }
        .${ styles.container + classNameContainer } {
          // width: calc(  ( (100% - (${ marginRight }px * (${ showElements - 1 }))) / ${ showElements }) * ${ children.length } )
          width: calc(  ( (100% + ${ marginRight }px) / ${ showElements }) * ${ children.length } )
        }
       `
      }
      </style>
      <div className={ styles.root } id={ styles.root + idRoot }>
        <div style={ { height: slideHeight, position: 'relative' } }>
          <div style={ {
            width: `calc(100% - (${ paddingLeft }px + ${ paddingRight }px))`, ...wrappStyle, ...customWrappStyle,
          } }
               className={ styles.wrapp + ' ' + classNameContainer }>
            <div className={ styles.container + ' ' + styles.container + classNameContainer }
                 onTouchStart={ disableSwipe ? null : touchStartHandler }
                 onTouchMove={ disableSwipe ? null : event => throttle(25, touchMoveHandler(event)) }
                 onTouchEnd={ disableSwipe ? null : touchEndHandler }
                 style={ { left: -(slideOption.currentIndex * (wrapElement.offsetWidth / (showElements))) + currentStartedX + 'px' } }>
              {
                !!isClientSlider ?
                  <Fragment>
                    { slideOption.currentIndex === 0 ? children.slice(0, 2 * showElements) : !!copyChildren.length ?
                      copyChildren.slice(0, (showElements === 1 ? 3 : showElements * 2)) : null
                    }
                  </Fragment> :
                  <Fragment>
                    { !!copyChildren.length ? copyChildren.slice(0, (showElements === 1 ? 3 : copyChildren.length)) : <>
                      { children.slice(0, isCustomServerSlice ? 4 : children.length) }
                    </> }
                  </Fragment>

              }
            </div>
          </div>
          { disableChangeSlide ? null :
            <>
              <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg"
                   className={ styles.arrowControl + ' ' + styles.arrowControlLeft }
                   onClick={ changeSlide.bind(null, -1) }>
                <circle r="20" transform="matrix(1 1.74846e-07 1.74846e-07 -1 20 20.6343)" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M19.6043 26.7796L13.8657 21.2487C13.5135 20.9093 13.5135 20.359 13.8657 20.0196L19.6043 14.4887C19.9564 14.1493 20.5274 14.1493 20.8795 14.4887C21.2317 14.8281 21.2317 15.3784 20.8795 15.7178L16.6803 19.7651L25.6016 19.7651L25.6016 21.5033L16.6803 21.5033L20.8795 25.5505C21.2317 25.8899 21.2317 26.4402 20.8795 26.7796C20.5274 27.119 19.9564 27.119 19.6043 26.7796Z"
                      fill="#333333"/>
              </svg>
              <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg"
                   className={ styles.arrowControl + ' ' + styles.arrowControlRight }
                   onClick={ changeSlide.bind(null, 1) }>
                <circle r="20" transform="matrix(1 1.74846e-07 1.74846e-07 -1 20 20.6343)" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M19.5988 14.4889L25.3375 20.0198C25.6896 20.3592 25.6896 20.9095 25.3375 21.2489L19.5988 26.7798C19.2467 27.1192 18.6758 27.1192 18.3236 26.7798C17.9715 26.4404 17.9715 25.8901 18.3236 25.5507L22.5228 21.5035H13.6016L13.6016 19.7653H22.5228L18.3236 15.718C17.9715 15.3786 17.9715 14.8283 18.3236 14.4889C18.6758 14.1495 19.2467 14.1495 19.5988 14.4889Z"
                      fill="#333333"/>
              </svg>

            </> }
        </div>
        { copyChildren.length <= showElements || hideBottomLine ? null :
          <div className={ styles.progressContainer }
               onClick={ setSlide }
               style={ {
                 width: rootElement.offsetWidth - (paddingLeft || 0) - (paddingRight || 0) + 'px', ...wrappStyle, ...customWrappStyle,
               } }>
            <div className={ styles.progress }
                 style={ { width: calcProgressIndex() * 100 / (copyChildren.length) + '%' } }/>
          </div>
        }
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  windowInnerWidth: state.window.size.windowInnerWidth,
  leftMenuIsOpen: state.global.leftMenuIsOpen
})

export default connect(mapStateToProps)(SlideShow)
